<template>
  <div id="app">
    <div class="background-image">
      <img src="bg.png" alt="Background Image" class="background-img" />
    </div>
    <div class="content">
      <h1 class="title hidden">$GymMeow</h1> <!-- 隐藏标题 -->
      <div class="text-container">
        <p class="text">
          $Phrygecap brings the spirit of the 2024 Paris Olympics to Solana! 
          Inspired by the iconic Phrygian cap, a symbol of freedom, 
          $Phrygecap combines tradition with innovation. 
          wear your $Phrygecap and join the movement on Solana!
        </p>
        <!-- 去掉分割线 -->
        <div class="additional-text" @click="copyText">
          <span>CA:</span><span class="copyable">KJaLk9RakZoSm7mXLmQ819PGhebiTswoELjdemopump</span>
        </div>
      </div>
      <button class="button" onclick="window.location.href='https://pump.fun/KJaLk9RakZoSm7mXLmQ819PGhebiTswoELjdemopump'">
        <span class="buy-text">BUY</span>
      </button>
    </div>

    <div class="social-icons">
      <div class="icon icon-twitter" @mouseover="hoverTwitter = true" @mouseleave="hoverTwitter = false" @click="redirectToTwitter">
        <span class="icon-bg" :class="{ 'hovered': hoverTwitter }"></span>
        <img v-if="!hoverTwitter" src="icon_tw@2x.png" class="icon-img" alt="Twitter">
        <img v-else src="icon_tw2@2x.png" class="icon-img" alt="Twitter">
      </div>
      <div class="icon icon-telegram" @mouseover="hoverTelegram = true" @mouseleave="hoverTelegram = false" @click="redirectToTelegram">
        <span class="icon-bg" :class="{ 'hovered': hoverTelegram }"></span>
        <img v-if="!hoverTelegram" src="icon_telg@2x.png" class="icon-img" alt="Telegram">
        <img v-else src="icon_telg2@2x.png" class="icon-img" alt="Telegram">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverTwitter: false,
      hoverTelegram: false
    };
  },
  mounted() {
    console.log(this.$refs.contractAddress); // 确认是否正确绑定
  },
  methods: {
    copyText() {
      const contractAddressText = document.querySelector('.copyable').innerText;
      navigator.clipboard.writeText(contractAddressText).then(() => {
        alert('Copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    },
    redirectToTwitter() {
      window.location.href = 'https://x.com/phryge_';
    },
    redirectToTelegram() {
      window.location.href = 'https://t.me/Phrygecap';
    }
  }
};
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'Paris2024-Variable';
  src: url('@/assets/fonts/Paris2024-Variable.ttf') format('truetype');
}

/* 全局设置字体 */
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Paris2024-Variable';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 60%; /* 向上移动内容 */
  left: 60%; /* 向右移动内容 */
  transform: translate(-50%, -50%);
  padding: 30px 40px; /* 增加填充以适应放大的文字 */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none; /* 隐藏元素 */
}

.title {
  font-size: 5.5em; /* 放大字体0.5倍 */
  color: black;
  margin: 0 40px 0 0; /* 调整间距以适应放大后的文字 */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px; /* 增大元素间距 */
  max-width: 600px; /* 调整内文的宽度 */
}

.text {
  font-size: 1.1em; /* 缩小字体 */
  color: white;
  margin: 0;
  position: relative;
  left: 850px; /* 向右移动 */
  top: 100px;
}

/* 去掉分割线 */
.divider {
  display: none;
}

.additional-text {
  font-size: 1.35em; /* 缩小字体 */
  color: white;
  cursor: pointer;
  position: relative;
  left: 850px; /* 向右移动 */
  top: 110px;
}

.additional-text:hover span:last-child {
  text-decoration: none;
  color: #F4D046; /* 鼠标悬停时颜色变化 */
}

.button {
  margin-right: 150%; /* 移动按钮位置 */
  margin-top: -10%;
  padding: 5px 30px; /* 缩小填充 */
  font-size: 4em; /* 缩小字体 */
  font-family: Paris2024-Variable;
  color: black; /* 默认文字颜色为黑色 */
  background-color: #F4D046; /* 默认背景颜色 */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s; /* 添加过渡效果 */
}

.button:hover {
  background-color: black; /* 悬停时背景颜色 */
  color: white; /* 悬停时文字颜色 */
  transform: translateY(-5px); /* 悬停时轻微向上移动 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 悬停时添加阴影 */
}

.buy-text {
  color: inherit; /* 继承父元素的颜色 */
}

.social-icons {
  position: absolute;
  top: 0px; /* 移动到顶部 */
  left: 1440px; /* 向左移动 */
  display: flex;
  flex-direction: column; /* 纵向排列 */
  align-items: center;
  z-index: 3;
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  margin: 7px 0; /* 调整图标之间的间距 */
  cursor: pointer;
  transition: transform 0.3s; /* 添加过渡效果 */
}

.icon-twitter {
  margin-top: 40px; /* 单独调整Twitter图标的位置 */
  margin-left: 120%;
}

.icon-telegram {
  margin-top: 20px; /* 单独调整Telegram图标的位置 */
}

.icon:hover {
  transform: translateY(-10px); /* 鼠标悬浮时向上移动 */
}

.icon-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: #F4D046; /* 默认背景颜色 */
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: -1;
}

.icon:hover .icon-bg {
  background-color: black; /* 悬停时背景颜色 */
}

.social-icons img {
  width: 26px;
  height: 26px;
}

.icon-img {
  position: relative;
  z-index: 1;
}
</style>
